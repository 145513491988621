/* eslint-disable */
(function () {
  if (window.performance && window.performance.mark) {
    window.performance.mark('scriptStartTime');
  }

  var isLocal = /local-eu1.hubspot(qa)?.com/.test(document.location.hostname);
  var isQaCustomDomain = /hs-sitesqa-eu1.com/.test(document.location.hostname);
  if (!isLocal && !isQaCustomDomain) {
    enviro.set('SENTRY_DEPLOYED', true);
  }

  if (window.Raven) {
    try {
      var isIframe = window.self !== window.top;
      window.Raven.setExtraContext({ isIframe: isIframe });
    } catch (e) {
      window.Raven.setExtraContext({ isIframe: 'unknown' });
    }
  }

  window.hubspot.meetingsHubletOverride = 'eu1';
})();


